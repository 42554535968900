import { Box, Button, Flex, Heading, Stack, } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client'

import { CartItem } from './CartItem'
import { CartOrderSummary } from './CartOrderSummary'
import { Cart as CartType } from '../../../types/Cart'
import EmptyCart from './EmptyCart';
import Loading from '../Helpers/Loading'
import { useAppState } from '../context'

const Cart: React.FC = () => {

  if (typeof window !== 'undefined') {
    if (!window.location.hash) {
      window.location = window.location + '#loaded';
      window.location.reload();
    }
  }
  
  // CART
  const { cart, setCart } = useAppState()

  const [maybeGetCart, { loading }] = useLazyQuery<CartQuery>(CART, {
    onCompleted: ({ cart }) => {
      setCart(cart)
    },

  })  

  // REMOVE ITEMS
  const [removeAllItems, { loading: loadingMutation , data}] = useMutation(REMOVE, {
    onCompleted() {
      setCart(cart)
    },
      refetchQueries: [{
      query: CART
    }],
  })

  // UPDATE 
  const [updateQuantities, { loading: updateLoading, data: updateData }] = useMutation(UPDATE, {
    onCompleted() {
      setCart(cart)
    }, 
    refetchQueries: [{
      query: CART
    }]
  })

  useEffect(() => {
    if (cart) {
      return
    }
    maybeGetCart()
  }, [cart, maybeGetCart ])

  if (loading) {
    return (
      <>
        <Loading></Loading>
      </>
    )
  }

  if (loadingMutation) {
    return (
      <>  
        <Loading></Loading>
      </>
    )
  }

  if (updateLoading) {
    return (
      <>  
        <Loading></Loading>
      </>
    )
  }

  if (!cart) {
    return (
      <>
        <Loading></Loading>
      </>
    )
  }

  if (cart?.contents.itemCount === 0) {
    return (
      <>
        <EmptyCart></EmptyCart>
      </>
    )
  }

  return (
    <>
      <Box minHeight="100vh" bg="white" width="100%" mx="auto" px={{ base: '4', md: '8', lg: '8em' }} py={{ base: '6', md: '8', lg: '32' }}>

        <Stack direction={{ base: 'column', lg: 'row' }} align={{ lg: 'flex-start' }} spacing={{ base: '8', md: '16' }}>

        <Stack spacing={{ base: '8', md: '10' }} flex="2">
          <Heading fontSize="2xl" fontWeight="extrabold">
            Shopping Cart ({(cart?.contents.itemCount)} items)
            </Heading>
            
            {cart?.contents.nodes.map(item => {
              // const { node } = item.product
              return <CartItem onClickDelete={() => removeAllItems({
              variables: {
                input: {
                  clientMutationId: `1234`,
                  all: false,
                  keys: item.key
                },
              },
            })
          } onChangeQuantity={(qty) => updateQuantities({
             variables: {
                key: item.key,
                quantity: qty
              },
          })} databaseId={item.product.node.databaseId} key={item.key} name={item.product.node.name} price={item.product.node.regularPrice} quantity={item.quantity} imageUrl={item.product.node.image?.sourceUrl} productTags={item.product.node.productTags} />
            })}
        </Stack>  

        {/* CART ORDER SUMMARY */}
        <Flex direction="column" align="center" flex="1">
          <CartOrderSummary subtotal={cart.subtotal} total={cart.total} shippingTotal={cart.shippingTotal } />
        </Flex>
        
        </Stack>
        </Box>
    </>
  )
}

type CartQuery = {
  cart?: CartType
}

const REMOVE = gql`
  mutation RemoveItemFromCart($input: RemoveItemsFromCartInput!) {
    removeItemsFromCart(input: $input) {
      cart {
        subtotal
        total
        shippingTotal
        contents {
          itemCount
          nodes {
            quantity
            key
            product {
              node {
                name
                sku
                databaseId
                ... on SimpleProduct {
                  price
                  regularPrice
                }
              }
            }
          }
        }
      }
    }
  }
`
const UPDATE = gql`
  mutation updateItemQuantities($key: ID!, $quantity: Int!){
  updateItemQuantities(input: {items: {key: $key, quantity: $quantity}})  {
    cart {
      subtotal
      total
      shippingTotal
      contents {
        itemCount
        nodes {
          quantity
          key
          product {
            node {
              id
              name
              databaseId
            }
          }
        }
      }
    }
  }
}
`
const CART = gql`
  query Cart {
    cart {
      subtotal
      total
      shippingTotal
      contents {
        itemCount
        nodes {
          quantity
          key
          product {
            node {
              id
              name
              sku
              databaseId
              image {
                sourceUrl
              }
              productTags {
                nodes {
                  name
                }
              }
              
              ... on SimpleProduct {
                regularPrice
                price
              }
            }
          }
        }
      }
    }
  }
`

export default Cart
