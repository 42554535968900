import {
    Button,
    Link
} from '@chakra-ui/react'

import { IconArrowRight } from '@tabler/icons';
import React from 'react'
import jwtDecode from 'jwt-decode'
import { useState } from 'react'

const CheckOutButton = () => {

    const [session] = useState(() => {
        try {

              if (typeof window !== 'undefined') {
                const jwtSession = window.localStorage.getItem('woo-session')

                const decoded = jwtDecode<{ data: { customer_id: String } }>(jwtSession)

                return decoded.data.customer_id
              }
           
        } catch (error: any) {
            console.error(error.message)

            return null
        }
    })

    return <Link href={`https://shop.cdihealth.net/checkout/?session_id=${session}`} _hover={{textDecoration: "none"}} ><Button colorScheme="cdicyan" width="100%" fontSize="md" rightIcon={<IconArrowRight height="16px" width="16px" />}> Checkout</Button></Link>
}

export default CheckOutButton